import { Box, Heading, Link, Stack, Text, VStack } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/components/navigation/navigation.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo/Seo";

SwiperCore.use([Autoplay]);

const PluginPage = () => {
  const contactRef = useRef(null);

  useEffect(() => {
    const background = contactRef.current.querySelectorAll(".background");

    const contactForm = contactRef.current.querySelectorAll(".contact-form");
    const fadeInElements = contactRef.current.querySelectorAll(".fadeInBottom");

    gsap
      .timeline({ defaults: { delay: 0.5 } })
      .set(contactRef.current, { opacity: 1 })
      .from(
        background,
        {
          opacity: 0,
          duration: 0.4,
        },
        0,
      )
      .from(
        fadeInElements,
        {
          opacity: 0,
          y: 50,
          duration: 0.6,
          stagger: 0.3,
        },
        0.3,
      )
      .from(
        contactForm,
        {
          opacity: 0,
          x: 50,
          duration: 0.6,
          stagger: 0.3,
        },
        0.6,
      );
  }, []);

  return (
    <Layout>
      <Seo />
      <Box
        ref={contactRef}
        pb="144px"
        pt={{ base: "120px", lg: "204px" }}
        overflow="hidden"
        width="100%"
        gridColumn="1/-1"
        position="relative"
      >
        <Stack
          opacity="1"
          display="flex"
          alignItems="start"
          flex="0 1 0%"
          maxWidth={{ base: "unset", "3xl": "1600px" }}
          px={{ base: "6", md: "10", lg: "20", "3xl": "0" }}
          margin="0px auto"
          spacing={{ base: "8", md: "25px", xl: "95px", "3xl": "250px" }}
          direction={{ base: "column", md: "row" }}
        >
          <VStack
            className="fadeInBottom"
            spacing={{ base: "6", md: "8" }}
            maxW="644px"
            alignItems="left"
            zIndex="38"
            paddingRight={{ base: 0, md: "50px" }}
            width={{ base: "100%", md: "50%", xl: "496px", "3xl": "554px" }}
            // pt={{ base: 0, md: "60px", lg: "58px" }}
          >
            <Heading
              as="h1"
              fontSize={{ base: "28px", md: "36px", xl: "48px", "3xl": "64px" }}
              mb={{ base: "0", "3xl": "6" }}
            >
              Motar.me plugin for gmail
            </Heading>
            <Stack spacing="4">
              <Text fontSize={{ base: "sm", "3xl": "lg" }}>
                Motar.me Plugin is a tool to automatically update bounces on our
                System when you integrate with your Gmail account. The extension
                continuously monitors your inbox and processes any outgoing mail
                to check whether the messages arrived at their destination and
                sends it to spreadsheets.
              </Text>
              <Text fontSize={{ base: "sm", "3xl": "lg" }}>
                Motar.me Plugin creates a folder called “Bounced Emails” in your
                Google drive with a spreadsheet containing thread ids of bounced
                messages. Our system then looks up this spreadsheet every few
                minutes and matches those ids with specific campaign emails and
                marks them as “Bounced”. From sending an email to automatically
                marking it as a bounce it can take about 5-7 minutes on average.
              </Text>
              <Text fontSize={{ base: "sm", "3xl": "lg" }}>
                With the extension you will not have to worry about manually
                updating the status of your campaign. You can access the Motar.me
                Plugin{" "}
                <Link href="https://chrome.google.com/webstore/detail/motarme-plugin/aofcdkfakhfbdbioeljolaloogebpgfa" textDecoration="underline">
                  here
                </Link>
                .
              </Text>
              <Text fontSize={{ base: "sm", "3xl": "lg" }}>
                If you are integrating with your Outlook account, you do not
                need the plugin for Motar.me to automatically update bounces.
              </Text>
            </Stack>
          </VStack>
          <Box
            bg="white"
            p={{ base: "4", md: "6", "3xl": "8" }}
            zIndex="33"
            width={{ base: "100%", md: "50%", xl: "489px", "3xl": "532px" }}
            className="contact-form"
          >
            <Swiper
              style={{
                display: "block",
                height: "100%",
              }}
              allowTouchMove={true}
              autoplay={{ delay: 3000 }}
            >
              <SwiperSlide>
                <StaticImage
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  preload="true"
                  alt="App mockup"
                  src="../images/plugin.png"
                />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  preload="true"
                  alt="App mockup"
                  src="../images/plugin-1.png"
                />
              </SwiperSlide>
            </Swiper>
          </Box>
        </Stack>
        <Box
          className="background"
          width={{ base: "67vw", md: "60vw" }}
          right="0"
          top="0"
          height={{ base: "433px", md: "516px", lg: "700px", "3xl": "584px" }}
          background="linear-gradient(161.1deg, #CDF7FF 12.75%, #7BDBEE 102.89%)"
          borderRadius="0px 0px 300px 0px"
          transform="matrix(-1, 0, 0, 1, 0, 0)"
          position="absolute"
          zIndex="30"
        />
      </Box>
    </Layout>
  );
};

export default PluginPage;
